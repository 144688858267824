import { MODAL_MODIFY_ORDER, MODAL_CLOSE } from '../actions/action_types';

export default function modalReducer(state = {}, action) {
  switch (action.type) {
    case MODAL_MODIFY_ORDER:
      return {
        ...state,
        modal_name: action.payload.modal_name,
        order: action.payload.order,
        max_amount_per_order: action.payload.max_amount_per_order,
      };
    case MODAL_CLOSE:
      return {
        ...state,
        modal_name: action.payload.modal_name,
      };
    default:
      return state;
  }
}
